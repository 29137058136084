import { useCallback, useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography } from '@mui/joy';

import { useLocale, useLocaleOptions } from 'hooks';
import { LocaleFlags } from 'icons';

import { LogoCentered } from 'elements/logo/LogoCentered';
import { MainWrapper } from 'elements/MainWrapper';
import LocaleList from './LocaleList';

export const LocalePage = ({ navigateToWelcome }) => {
  const [
    locale,
    setLocale,
    {
      localeUpdating,
      updateRespondentLocale
    }
  ] = useLocale();

  const {
    locales,
    survey_locales,
    account_organisation_name,
    image
  } = useOutletContext();

  const { t } = useTranslation('locale_page');
  const localeOptions = useLocaleOptions(survey_locales, locales);

  const onClick = useCallback(
    async () => {
      await updateRespondentLocale(locale);
      navigateToWelcome();
    },
    [locale, updateRespondentLocale, navigateToWelcome]
  );

  const localesWithFlags = useMemo(
    () => localeOptions.map(
      survey_locale => {
        const Flag = LocaleFlags(survey_locale.value);
        return {
          ...Flag && ({ icon: <Flag size={16} ariaHidden={'true'} /> }),
          ...survey_locale,
        };
      }
    ),
    [localeOptions]
  );

  return (
    <MainWrapper className={'app__centered'}>
      <LogoCentered
        image={image}
        account_organisation_name={account_organisation_name}
      />
      <Stack
        alignItems={'center'}
        spacing={{ xs: 5, md: 8 }}
      >
        <Stack
          alignItems={'center'}
          spacing={{ xs: 5, md: 8 }}
        >
          <Typography level={'h1'} mb={2}>
            {t('Select language')}
          </Typography>

          <LocaleList locale={locale} locales={localesWithFlags} onChange={setLocale} />
        </Stack>

        <Button
          className={'large-button'}
          size={'lg'}
          data-component={'locale-page-start-button'}
          disabled={locale == ''}
          onClick={onClick}
          loading={localeUpdating}
        >
          {t('Go to survey')}
        </Button>
      </Stack>
    </MainWrapper>
  );
};

export default LocalePage;
