import { Chip, Radio, RadioGroup, Typography } from '@mui/joy';

const LocaleSelectDisplay = ({ icon, label }) => (
  <Typography startDecorator={icon}>
    {label}
  </Typography>
);

const LocaleOption = (props) => {
  const {
    value,
    label,
    icon,
    selectedLocale,
    onChange
  } = props;

  const checked = selectedLocale === value;

  return (
    <Chip
      key={value}
      color={checked ? 'primary' : 'neutral'}
      variant={checked ? 'solid' : 'plain'}
      data-key={value}
      sx={{
        '--Chip-minHeight': '40px',
        px: 2,
        py: 1,
        maxWidth: '100%',
        whiteSpace: 'wrap',
        textAlign: 'center'
      }}
    >
      <Radio
        label={(<LocaleSelectDisplay icon={icon} label={label} />)}
        value={value}
        checked={checked}
        variant={checked ? 'solid' : 'outlined'}
        onChange={(event) => {
          if (event.target.checked) {
            onChange(value);
          }
        }}
      />
    </Chip>
  );
};

const LocaleList = (props) => {
  const {
    locale,
    locales,
    onChange
  } = props;

  return (
    <RadioGroup
      name={'locale'}
      orientation={'horizontal'}
      data-component={'locale-list-radio-group'}
      disableIcon
      overlay
      sx={{
        flexWrap: 'wrap',
        gap: 3,
        justifyContent: 'center',
        direction: 'ltr'
      }}
    >
      {locales.map(({ value, label, icon }) => (
        <LocaleOption
          key={value}
          value={value}
          label={label}
          selectedLocale={locale}
          icon={icon}
          onChange={onChange}
        />
      ))}
    </RadioGroup>
  );
};

export default LocaleList;
