import { useMemo } from 'react';
import { LOCALE_CODES } from 'lib/Enum';

const isValidSurveyLocaleData = (survey_locales) => (
  isArrayWithValues(survey_locales) && typeof survey_locales[0] === 'object'
);

const isArrayWithValues = (arr) => (
  Array.isArray(arr) && arr.length > 0
);

const buildFromLocaleCodes = (locales) => {
  const availableLocales = isArrayWithValues(locales) ? locales : ['en'];

  return Object.entries(LOCALE_CODES)
    .filter(([localeName]) => availableLocales.includes(localeName))
    .map(([value, label]) => ({ value, label }));
};

export const useLocaleOptions = (survey_locales, locales) => (
  useMemo(
    () => (
      isValidSurveyLocaleData(survey_locales)
        ? survey_locales
        : buildFromLocaleCodes(locales)
    ),
    [survey_locales, locales]
  )
);
